define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-chooser", ["exports", "ember", "select-kit/components/multi-select", "@ember/object"], function (_exports, _ember, _multiSelect, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    makeArray
  } = _ember.default;
  var _default = _exports.default = _multiSelect.default.extend({
    classNames: ["house-ads-chooser"],
    filterable: true,
    filterPlaceholder: "admin.adplugin.house_ads.filter_placeholder",
    tokenSeparator: "|",
    allowCreate: false,
    allowAny: false,
    settingValue: "",
    valueAttribute: null,
    nameProperty: null,
    value: (0, _object.computed)("settingValue", function () {
      return this.settingValue.toString().split(this.tokenSeparator).filter(Boolean);
    }),
    // TODO: kept for legacy, remove when Discourse is 2.5
    mutateValues(values) {
      this.set("settingValue", values.join(this.tokenSeparator));
    },
    computeValues() {
      return this.settingValue.split(this.tokenSeparator).filter(Boolean);
    },
    content: (0, _object.computed)("choices", function () {
      return makeArray(this.choices);
    }),
    actions: {
      onChange(value) {
        const settingValue = makeArray(value).join(this.tokenSeparator);
        this.attrs.onChange && this.attrs.onChange(settingValue);
      }
    }
  });
});