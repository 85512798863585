define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-show", ["exports", "@ember/object", "discourse/routes/discourse", "I18n"], function (_exports, _object, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      if (params.ad_id === "new") {
        return _object.default.create({
          name: _I18n.default.t("admin.adplugin.house_ads.new_name"),
          html: "",
          visible_to_logged_in_users: true,
          visible_to_anons: true
        });
      } else {
        return this.modelFor("adminPlugins.houseAds").findBy("id", parseInt(params.ad_id, 10));
      }
    }
  });
});